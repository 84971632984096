
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { GetServerSideProps, NextPage } from 'next'
import { getSession } from 'next-auth/react'
// import { getSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'

import AuthForm from '@/components/forms/AuthForm'
import BaseLayout from '@/components/layouts/BaseLayout'
import AuthLeftSide from '@/components/ui/AuthLeftSide'
import { NAMESPACE_LOGIN } from '@/res/namespaces'
import { ROUTE_PROFILE } from '@/res/routes'
// import { ROUTE_PROFILE } from '@/res/routes'

const LoginPage: NextPage = () => {
  const { t } = useTranslation(NAMESPACE_LOGIN)

  return (
    <BaseLayout
      additionalMetaTags={[
        {
          content: keywords.join(','),
          name: 'keywords',
        },
      ]}
      seoTitle={t('seo_title')}
      seoDescription={t('seo_description')}
    >
      <div className="flex min-h-screen">
        <div className="flex flex-1 items-center justify-between mx-auto laptop:min-w-[900px] max-w-7xl">
          <div className="w-full flex laptop:mt-0 small:mt-10 mt-0 justify-around laptop:flex-row flex-col-reverse desktop:gap-0 items-center gap-y-12 small:max-w-[80vw] rounded-lg py-10 small:px-8 px-4 mx-auto bg-white">
            <AuthLeftSide />
            <AuthForm type="login" />
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}

 const _getServerSideProps: GetServerSideProps = async (ctx) => {
  const session = await getSession(ctx)

  if (session?.user?.accessToken) {
    return {
      redirect: {
        destination: ROUTE_PROFILE,
        permanent: false,
      },
    }
  }

  return {
    props: {},
  }
}

export default LoginPage

const keywords = [
  'Один аккаунт для всего',
  'Один аккаунт',
  'Единый аккаунт',
  'Регистрация uz',
]


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  